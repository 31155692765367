import Vue from 'vue'
import { Settings, DateTime, Interval } from 'luxon'
import { Message } from 'element-ui'
import chroma from 'chroma-js'

import parameterize from 'parameterize-js'
import smcb_axios from '@core/lib/smcb_axios'

const locale = I18n.locale || 'en'
const ordinal_plural_rules = new Intl.PluralRules(locale, { type: 'ordinal' })

function dd(number) {
  return number.toString().padStart(2, '0')
}

function vl_utils(Vue) {
  return {
    default_color_palette() {
      return Object.freeze([
        '#9D2556',
        '#E15C32',
        '#DEC359',
        '#397E48',
        '#444FAF',
        '#812CA1',
        '#C63460',
        '#DF722C',
        '#BFC950',
        '#419487',
        '#7A83C6',
        '#735549',
        '#C1281B',
        '#E19535',
        '#88B252',
        '#4299DF',
        '#B09ED8',
        '#616060',
        '#D88075',
        '#EDC04A',
        '#5DB17C',
        '#5283EB',
        '#966BAA',
        '#A49B8D',
      ])
    },

    max(x, y) {
      return x >= y ? x : y
    },

    min(x, y) {
      return x <= y ? x : y
    },

    //
    // Remove item from array, or element at position ix
    // Source object remains untouched.
    // Either if ix is set, item is ignored.
    //
    remove(array, ix = null, item = null) {
      array = [...array]
      ix = ix === null ? array.indexOf(item) : ix
      if (ix >= 0) array.splice(ix, 1)
      return array
    },

    index_by(array, transformer) {
      let f = transformer
      if (typeof transformer === 'string') {
        f = obj => obj[transformer]
      }

      const result = {}
      for (const obj of array) {
        result[f(obj)] = obj
      }
      return result
    },

    rgb2hex(rgb) {
      let a = rgb.split('(')[1].split(')')[0]
      a = a.split(',')
      let b = a.map(x => {
        x = parseInt(x).toString(16)
        return x.length == 1 ? '0' + x : x
      })
      return '#' + b.join('')
    },

    string_to_hex_color(str) {
      let hash = 0

      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }

      let color = '#'

      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        color += ('00' + value.toString(16)).slice(-2)
      }

      return color
    },

    text_color_on_contrast(bg_color, fallback = '#000000', dark = '#000000', bright = '#ffffff') {
      if (bg_color && chroma.valid(bg_color)) {
        const black_contrast = chroma.contrast(bg_color, '#000')
        const white_contrast = chroma.contrast(bg_color, '#fff')
        // chroma(bg_color).luminance()
        return black_contrast > white_contrast ? dark : bright
      }

      return fallback
    },

    generate_color_suggestions(color, number = 5) {
      if (chroma.valid(color)) {
        let color1 = chroma(color)
        let color2 = color1.luminance() > 0.5 ? color1.darken(3) : color1.brighten(3)
        return chroma
          .scale([color1, color2])
          .correctLightness()
          .colors(number, 'hex')
          .map(c => c.toUpperCase())
      }

      return []
    },

    show_default_alert(text) {
      Message({
        message: text,
        showClose: true,
        customClass: 'bg-white shadow-sm',
      })
    },

    show_success_alert(text) {
      Message({
        message: text,
        type: 'success',
        showClose: true,
        customClass: 'shadow-sm',
      })
    },

    show_info_alert(text) {
      Message({
        message: text,
        type: 'info',
        showClose: true,
        customClass: 'shadow-sm',
      })
    },

    show_warning_alert(text) {
      Message({
        message: text,
        type: 'warning',
        showClose: true,
        customClass: 'shadow-sm',
      })
    },

    show_error_alert(text, error) {
      console.error(text, error)
      Message({
        message: text,
        type: 'error',
        showClose: true,
        customClass: 'shadow-sm',
      })
    },

    show_permanent_warning_alert({ message, offset = 0, onCloseFunction = () => {} }) {
      Message({
        message: message,
        type: 'warning',
        showClose: true,
        customClass: 'shadow-sm',
        duration: 0,
        offset: offset,
        onClose: onCloseFunction,
      })
    },

    show_success_alert_with_offset({ text, offset = 0 }) {
      Message({
        message: text,
        type: 'success',
        showClose: true,
        customClass: 'shadow-sm',
        offset: offset,
      })
    },

    show_error_alert_with_offset({ text, offset = 0 }) {
      console.error(text)
      Message({
        message: text,
        type: 'error',
        showClose: true,
        customClass: 'shadow-sm',
        offset: offset,
      })
    },

    clear_all_alerts() {
      Message.closeAll()
    },

    //
    // Renames the keys of a JS object
    //
    rename_keys(keys, obj) {
      for (const [short, long] of Object.entries(keys)) {
        if (obj[short]) {
          obj[long] = obj[short]
          delete obj[short]
        }
      }
      return obj
    },

    is_blank(value) {
      return value === undefined || value === null || value === ''
    },

    is_blank_trim(value) {
      return value === undefined || value === null || value.trim() === ''
    },

    obj_full(obj) {
      return Object.keys(obj || {}).length > 0
    },

    async delay(milliseconds) {
      await new Promise(resolve => setTimeout(resolve, milliseconds))
    },

    scroll_to_after_tick(element_id) {
      Vue.nextTick(() => {
        this.scroll_to(element_id)
      })
    },

    scroll_to(element_id) {
      const element = document.getElementById(element_id)
      if (!element) {
        console.warn('VL Trying to scroll to non-existing div:', element_id)
        return
      }
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // NOTE: 'nearest' could also be an option
      })
    },

    range(length, f_generator) {
      return Array.from({ length }, (_, ix) => f_generator(ix))
    },

    array_avg(array) {
      if (!array || array.length === 0) return 0
      return this.array_sum(array) / array.length
    },

    array_sum(array) {
      if (!array) return 0
      return array.reduce((sum, x) => sum + x, 0)
    },

    sort_by(array, f_getter, reverse) {
      const positive = reverse ? -1 : 1
      return [...array].sort((a, b) => {
        a = f_getter(a)
        b = f_getter(b)
        if (a < b) return -positive
        if (a > b) return positive
        return 0
      })
    },

    is_running_noq() {
      return window.IS_RUNNING_NOQ
    },

    is_running_gyms() {
      return !window.IS_RUNNING_NOQ
    },

    parameterize_string(string, separator = '-') {
      return parameterize(string, { separator })
    },

    is_number(num) {
      return typeof num === 'number' && !isNaN(num)
    },

    is_not_number(num) {
      return !this.is_number(num)
    },

    round_with_precision(num, precision) {
      const coefficient = Math.pow(10, precision)
      return Math.round((num + Number.EPSILON) * coefficient) / coefficient
    },

    decimals_to_percentage(num, precision) {
      if (this.is_number(num)) return this.round_with_precision(num * 100, precision)
      return null
    },

    percentage_to_decimal(num, precision) {
      if (this.is_number(num)) return this.round_with_precision(num / 100, precision)
      return null
    },

    is_valid_url(url) {
      try {
        new URL(url)
      } catch (e) {
        return false
      }
      return true
    },

    root_path(suffix = '') {
      const fullpath = window.location.pathname
      const [language, location, slug] = fullpath.split('/').filter(Boolean)
      return `/${language}/${location}/${slug}${suffix}`
    },

    //
    // Example args:
    //   split = '/setup'
    //   suffix = '/additional_software'
    //
    cousin_path(split, suffix = '') {
      const fullpath = window.location.pathname
      const last_index = fullpath.lastIndexOf(split)
      if (last_index > 0) return fullpath.slice(0, last_index) + suffix
      return fullpath + suffix
    },

    sibling_path(split, suffix = '') {
      const fullpath = window.location.pathname
      const last_index = fullpath.lastIndexOf(split)
      if (last_index > 0) return fullpath.slice(0, last_index) + split + suffix
      return fullpath + split + suffix
    },

    compare_str(str_a, str_b) {
      if (!str_a && !str_b) return 0
      if (!str_a) return 1
      if (!str_b) return -1
      return str_a.localeCompare(str_b, locale, { sensitivity: 'base' })
    },

    compare_employees_by_fullname(employee_a, employee_b) {
      if (!employee_a && !employee_b) return 0
      if (!employee_a) return 1
      if (!employee_b) return -1

      const firstname_comparison = this.compare_str(employee_a.firstname, employee_b.firstname)
      if (firstname_comparison !== 0) return firstname_comparison

      const lastname_comparison = this.compare_str(employee_a.lastname, employee_b.lastname)
      if (lastname_comparison !== 0) return lastname_comparison

      // Also compare by e-mail to make sure the the ordering stays consistent even in case of homonyms
      return this.compare_str(employee_a.email, employee_b.email)
    },

    get_employee_fullname(employee) {
      return `${employee.firstname} ${employee.lastname}`
    },

    get_employee_initials(employee) {
      const initials = []

      if (employee.firstname) {
        initials.push(employee.firstname.charAt(0))
      }

      if (employee.lastname) {
        initials.push(employee.lastname.charAt(0))
      }

      return initials.join('').toLocaleUpperCase(locale)
    },

    debounce(fn, timeout = 200) {
      let timer

      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          fn.apply(this, args)
        }, timeout)
      }
    },

    transform_values(object, map_fn) {
      const entries = Object.entries(object)
      const transformed_entries = entries.map(([key, value]) => [key, map_fn(value)])
      return Object.fromEntries(transformed_entries)
    },

    uniq(arr) {
      return [...new Set(arr)]
    },

    partition(arr, fn) {
      return arr.reduce(
        ([pass, fail], item) => {
          const item_pass = fn(item)

          if (item_pass) {
            pass.push(item)
          } else {
            fail.push(item)
          }

          return [pass, fail]
        },
        [[], []]
      )
    },

    rand_alphanum(n) {
      return [...Array(n).keys()].map(() => Math.floor(Math.random() * 36).toString(36)).join('')
    },

    time_duration_description_in_minutes(str) {
      if (this.is_blank_trim(str)) return 0

      let [value, unit] = str.split(':')
      value = parseInt(value)
      if (isNaN(value)) return 0
      if (unit === 'days') value = value * 24 * 60
      if (unit === 'hours') value = value * 60
      return value
    },

    containsOnlyWhitespace: str => str.trim().length === 0 || /^\s+$/g.test(str),
  }
}

const vl_time = {
  set(time_zone) {
    if (!time_zone) {
      Vue.$vl_utils.show_error_alert('Please set your time zone in Localisation under Gym profile!')
      time_zone = 'UTC'
    }
    console.info('Setting Timezone to', time_zone)
    Settings.defaultZoneName = time_zone
    Settings.throwOnInvalid = true
  },

  get_active_timezone() {
    return Settings.defaultZoneName || 'UTC'
  },

  get_x_months_ago(count) {
    return DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).minus({ month: count })
  },

  utc_from_iso(iso_date_string) {
    return DateTime.fromISO(iso_date_string, { setZone: 'UTC' })
  },

  last_second_of_today() {
    return DateTime.local().set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
  },

  utc_h12() {
    return DateTime.utc().set({ hour: 12 })
  },

  hhmm_to_minutes(hhmm) {
    if (!hhmm) return null
    let [h, m] = hhmm.split(':').map(s => parseInt(s, 10))
    return h * 60 + m
  },

  minutes_to_hhmm(minutes) {
    const h = dd(Math.floor(minutes / 60))
    const m = dd(minutes % 60)
    return `${h}:${m}`
  },

  task_minute_count(task, from = null, to = null) {
    if (!task.start) return 0
    if (!task.is_all_day && !task.end) return 0
    const start = from ? DateTime.max(from, task.start) : task.start
    const end = task.end && to ? DateTime.min(to, task.end) : task.end
    const one_minute = 60000

    if (task.is_all_day) {
      if (end) return Math.ceil(end.diff(start).as('days')) * 8 * 60
      return 8 * 60
    }

    return end.diff(start).milliseconds / one_minute
  },

  js_as_date(js_date) {
    return this.js_as_local(js_date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  },

  js_as_local(js_date) {
    if (!js_date) return null
    return DateTime.fromJSDate(js_date)
  },

  js_to_format(js_date, format) {
    if (!js_date) return null
    return this.js_as_local(js_date).toFormat(format)
  },

  parse_as_local_day(str) {
    return DateTime.fromFormat(str, 'yyyy-MM-dd')
  },

  parse_as_day(str) {
    return DateTime.fromFormat(str, 'yyyy-MM-dd', { zone: 'utc' })
  },

  parse_as_datetime(str) {
    return DateTime.fromFormat(str, 'yyyy-MM-dd hh:mm', { zone: 'utc' })
  },

  parse_as_local_datetime(str) {
    return DateTime.fromFormat(str, 'yyyy-MM-dd hh:mm')
  },

  parse_as_local_with_format(str, format) {
    return DateTime.fromFormat(str, format)
  },

  parse_as_local(str) {
    if (!str) return null
    return DateTime.fromISO(str)
  },

  parse_finance_as_local(str) {
    if (!str) return null
    // TODO: Convert to system TZ:
    return DateTime.fromFormat(str.slice(0, -4), 'yyyy-MM-dd hh:mm:ss', { zone: 'utc' })
  },

  parse_checkout_str_as_local(str) {
    if (!str) return null
    return DateTime.fromFormat(str, "yyyy-MM-dd'T'hh:mm:ss'UTC'", { zone: 'UTC' }).toLocal()
  },

  parse_time(hhmm) {
    try {
      return DateTime.fromFormat(hhmm, 'HH:mm')
    } catch (e) {
      return null
    }
  },

  get_one_month_ago() {
    return this.get_x_months_ago(1)
  },

  get_today() {
    return DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  },

  get_today_str() {
    return DateTime.local().toFormat('yyyy-MM-dd')
  },

  now() {
    return DateTime.local()
  },

  equals_month_ago_day_range(count, from, to) {
    if (DateTime.isDateTime(to) && DateTime.isDateTime(from)) {
      let today = DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      let monthsAgo = today.minus({ month: count })
      return +from === +monthsAgo && +to === +today
    } else {
      console.warn('Using deprecated date obj', from, to)
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      let oneMonthAgo = new Date()
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - count)
      oneMonthAgo.setHours(0, 0, 0, 0)
      return to.getTime() === today.getTime() && from.getTime() === oneMonthAgo.getTime()
    }
  },

  is_overlapping(start_a, end_a, start_b, end_b) {
    if (!DateTime.isDateTime(start_a) || !DateTime.isDateTime(start_b) || (end_a && !DateTime.isDateTime(end_a)) || (end_b && !DateTime.isDateTime(end_b)))
      throw new Error('Expecting DateTime in is_overlapping')

    if (end_a) {
      if (end_b) {
        if (start_a <= end_b && end_a >= start_b) return true
      } else {
        if (start_b <= end_a) return true
      }
    } else {
      if (end_b) {
        if (end_b >= start_a) return true
      } else {
        return true
      }
    }

    return false
  },

  get_months_in_interval(from, to) {
    const interval = Interval.fromDateTimes(from.startOf('month'), to.endOf('month'))
    const months = []

    let cursor = interval.start

    while (cursor < interval.end) {
      months.push([cursor.month, cursor.year])
      cursor = cursor.plus({ months: 1 })
    }

    return months
  },

  get_ruby_day_of_week_index(datetime) {
    if (!DateTime.isDateTime(datetime)) throw new Error('Expecting DateTime')
    return datetime.weekday % 7
  },

  are_on_same_date(dt1, dt2) {
    if (!DateTime.isDateTime(dt1) || !DateTime.isDateTime(dt2)) throw new Error('Expecting DateTimes')
    return dt1.hasSame(dt2, 'day') && dt1.hasSame(dt2, 'month') && dt1.hasSame(dt2, 'year')
  },
}

const localisation = {
  language_name(code) {
    return {
      en: 'English',
      de: 'Deutsch',
      it: 'Italiano',
      ja: '日本語',
      nl: 'Nederlands',
      es: 'Español',
      fr: 'Français',
      cs: 'Česky',
      tr: 'Türkçe',
      pl: 'Polski',
      ro: 'Limba română',
      sk: 'Slovenčina',
      sl: 'Slovenščina',
      uk: 'Українська',
      ru: 'Русский',
    }[code]
  },

  preferred_public_languages() {
    return ['en', 'de', 'it', 'ja', 'nl', 'es', 'fr', 'cs']
  },

  backoffice_languages() {
    return ['en', 'de', 'it', 'ja', 'nl', 'es', 'fr']
  },

  format_time_including_seconds(settings, dateTime) {
    if (!dateTime) return ''
    if (DateTime.isDateTime(dateTime)) {
      return dateTime.toFormat(this.time_template_with_seconds(settings))
    } else {
      console.warn('Using deprecated date obj in formatTime()', dateTime)
    }
  },

  time_template_with_seconds(settings) {
    switch (settings.time_format) {
      case '12':
        return 'hh:mm:ss a'
      case '24':
      default:
        return 'HH:mm:ss'
    }
  },

  date_template(settings, separator) {
    if (typeof separator === 'undefined' || separator === null) separator = '/'

    switch (settings.date_format) {
      case 'MDY':
        return `MM${separator}dd${separator}yyyy`
      case 'YMD':
        return `yyyy${separator}MM${separator}dd`
      case 'DMY':
      default:
        return `dd${separator}MM${separator}yyyy`
    }
  },

  verbose_date_template(settings) {
    switch (settings.date_format) {
      case 'MDY':
        return 'EEE, MMM dd yyyy'
      case 'YMD':
        return 'EEE, yyyy MMM dd'
      case 'DMY':
      default:
        return 'EEE, dd. MMM yyyy'
    }
  },

  day_month_template(settings, separator) {
    if (typeof separator === 'undefined' || separator === null) separator = '/'

    switch (settings.date_format) {
      case 'MDY':
      case 'YMD':
        return `MM${separator}dd`
      case 'DMY':
      default:
        return `dd${separator}MM`
    }
  },

  time_template(settings) {
    switch (settings.time_format) {
      case '12':
        return 'hh:mm a'
      case '24':
      default:
        return 'HH:mm'
    }
  },

  fullcalendar_slot_label_format(settings) {
    return {
      hour: 'numeric',
      minute: '2-digit',
      omitZeroMinute: settings.time_format === '12',
      hour12: settings.time_format === '12',
    }
  },

  fullcalendar_event_time_format(settings) {
    return {
      hour: 'numeric',
      minute: '2-digit',
      hour12: settings.time_format === '12',
    }
  },

  format_to_ics(dt, is_all_day = false) {
    let date = new Date(dt)
    if (is_all_day) return [date.getFullYear(), date.getMonth() + 1, date.getDate()]
    return [date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes()]
  },

  format_date_str(settings, date_str, separator = '/') {
    if (!date_str) return ''
    let dateObj = new Date(date_str)
    switch (settings.date_format) {
      case 'MDY':
        return dd(dateObj.getMonth() + 1) + separator + dd(dateObj.getDate()) + separator + dateObj.getFullYear()
      case 'YMD':
        return dateObj.getFullYear() + separator + dd(dateObj.getMonth() + 1) + separator + dd(dateObj.getDate())
      case 'DMY':
      default:
        return dd(dateObj.getDate()) + separator + dd(dateObj.getMonth() + 1) + separator + dateObj.getFullYear()
    }
  },

  // TODO: Consider showing year if different from current year
  format_date_no_year(settings, date_str, separator = '/') {
    if (!date_str) return ''
    let dateObj = new Date(date_str)
    switch (settings.date_format) {
      case 'DMY':
        return dd(dateObj.getDate()) + separator + dd(dateObj.getMonth() + 1)
      default:
        return dd(dateObj.getMonth() + 1) + separator + dd(dateObj.getDate())
    }
  },

  format_date(settings, dateTime, separator = '/') {
    if (!dateTime) return ''
    if (DateTime.isDateTime(dateTime)) {
      return dateTime.toFormat(this.date_template(settings, separator))
    } else {
      console.warn('Using deprecated date obj in formatDate()', dateTime)
    }

    // TODO: Deprecated, remove all calls using native Date object
    let dateObj = typeof dateTime === 'object' ? dateTime : new Date(dateTime)
    switch (settings.date_format) {
      case 'MDY':
        return dd(dateObj.getMonth() + 1) + separator + dd(dateObj.getDate()) + separator + dateObj.getFullYear()
      case 'YMD':
        return dateObj.getFullYear() + separator + dd(dateObj.getMonth() + 1) + separator + dd(dateObj.getDate())
      case 'DMY':
      default:
        return dd(dateObj.getDate()) + separator + dd(dateObj.getMonth() + 1) + separator + dateObj.getFullYear()
    }
  },

  format_date_abbrev($i18n, dateTime) {
    if (!dateTime) return ''
    if (DateTime.isDateTime(dateTime)) {
      const month = $i18n.t(`month_names.${dateTime.month - 1}`).substring(0, 3)
      return `${month} ${dateTime.toFormat('d')}`
    } else {
      console.warn('Using deprecated date obj in format_time()', dateTime)
    }
  },

  format_date_abbrev_with_year($i18n, dateTime) {
    if (!dateTime) return ''
    if (DateTime.isDateTime(dateTime)) {
      const month = $i18n.t(`month_names.${dateTime.month - 1}`).substring(0, 3)
      return `${dateTime.toFormat('d')} ${month} ${dateTime.toFormat('yyyy')}`
    } else {
      console.warn('Using deprecated date obj in format_time()', dateTime)
    }
  },

  format_time(settings, dateTime) {
    if (!dateTime) return ''
    if (DateTime.isDateTime(dateTime)) {
      return dateTime.toFormat(this.time_template(settings))
    } else {
      console.warn('Using deprecated date obj in format_time()', dateTime)
    }

    // TODO: Deprecated, remove all calls using native Date object
    let dateObj = typeof dateTime === 'object' ? dateTime : new Date(dateTime)
    switch (settings.time_format) {
      case '24':
        return dd(dateObj.getHours()) + ':' + dd(dateObj.getMinutes())
      case '12':
        let hours = dateObj.getHours()
        let suffix = hours < 12 ? 'AM' : 'PM'
        hours = hours === 0 ? 12 : dd(hours)
        return hours + ':' + dd(dateObj.getMinutes()) + ' ' + suffix
      default:
        return dd(dateObj.getHours()) + ':' + dd(dateObj.getMinutes())
    }
  },

  format_hour_minute_duration({ minutes, default_duration_unit = 'h', add_space = false }) {
    const h = Math.floor(minutes / 60)
    const m = Math.floor(minutes % 60)
    const space = add_space ? ' ' : ''
    if (h === 0 && m === 0) return `0${space}${default_duration_unit}`

    let result = ''
    if (h > 0) result += `${h}${space}h`
    if (m > 0) {
      if (result.length > 0) result += ' '
      result += `${m}${space}m`
    }
    return result
  },

  format_date_time(settings, dateTime) {
    return this.format_date(settings, dateTime) + ' ' + this.format_time(settings, dateTime)
  },

  format_verbose_date(settings, dt) {
    return dt.toFormat(this.verbose_date_template(settings))
  },

  format_weekday_date(settings, dt, $i18n) {
    const date = this.format_date(settings, dt)
    if (!date) return null

    // Luxon's DateTime.weekday is cached, and mutates the object itself, raising a Vuex error.
    const cloned = dt.plus(0)

    const weekday = this.format_abbrev_day(cloned.weekday, $i18n)
    return `${weekday}, ${date}`
  },

  format_abbrev_weekday(settings, dt, $i18n) {
    if (typeof dt === 'string') {
      dt = Vue.$vl_time.parse_as_day(dt)
    } else {
      if (!this.format_date(settings, dt)) return null
    }

    // Luxon's DateTime.weekday is cached, and mutates the object itself, raising a Vuex error.
    const cloned = dt.plus(0)

    return this.format_abbrev_day(cloned.weekday, $i18n)
  },

  format_weekday(settings, dt, $i18n) {
    if (typeof dt === 'string') {
      dt = Vue.$vl_time.parse_as_day(dt)
    } else {
      if (!this.format_date(settings, dt)) return null
    }

    // Luxon's DateTime.weekday is cached, and mutates the object itself, raising a Vuex error.
    const cloned = dt.plus(0)

    return this.format_day(cloned.weekday, $i18n)
  },

  format_day(day_index, $i18n) {
    return $i18n.t(`day_names.${day_index % 7}`)
  },

  format_abbrev_day(day_index, $i18n) {
    return $i18n.t(`abbrev_day_names.${day_index % 7}`)
  },

  format_date_to_day_month(settings, dt, separator) {
    if (!dt) return ''
    if (DateTime.isDateTime(dt)) {
      return dt.toFormat(this.day_month_template(settings, separator))
    } else {
      console.warn('Using deprecated date obj in format_date_to_day_month()', dt)
    }
  },

  format_date_range(settings, start, end, is_all_day, $i18n) {
    const fr_date = this.format_weekday_date(settings, start, $i18n)
    const to_date = this.format_weekday_date(settings, end, $i18n) || null
    const fr_time = this.format_time(settings, start)
    const to_time = this.format_time(settings, end) || null
    if (is_all_day) {
      if (to_date === null || fr_date === to_date) return fr_date
      return `${fr_date} - ${to_date}`
    }
    if (fr_date === to_date) return `${fr_date}, ${fr_time} - ${to_time}`
    return `${fr_date}, ${fr_time} - ${to_date}, ${to_time}`
  },

  format_price(settings, amount, currencyAsText = false, precision = 2) {
    const negativeSign = amount < 0 ? '-' : ''
    const delimiter = (settings.currency_format && settings.currency_format.delimiter) || '.'
    const separator = (settings.currency_format && settings.currency_format.separator) || ','
    const currencySign = (settings.currency_format && settings.currency_format.sign) || '€'
    const currencyText = (settings.currency_format && settings.currency_format.text) || 'EUR'

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(precision))).toString()
    let j = i.length > 3 ? i.length % 3 : 0

    return (
      (currencyAsText ? '' : currencySign + ' ') +
      negativeSign +
      (j ? i.substr(0, j) + delimiter : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + delimiter) +
      (precision
        ? separator +
          Math.abs(amount - i)
            .toFixed(precision)
            .slice(2)
        : '') +
      (currencyAsText ? ' ' + currencyText : '')
    )
  },

  format_decimal(settings, dec, precision = 0) {
    const negativeSign = dec < 0 ? '-' : ''
    const delimiter = settings.currency_delimiter || '.'
    const separator = settings.currency_separator || ','

    let i = parseInt((dec = Math.abs(Number(dec) || 0).toFixed(precision))).toString()
    let j = i.length > 3 ? i.length % 3 : 0
    return (
      negativeSign +
      (j ? i.substr(0, j) + delimiter : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + delimiter) +
      (precision
        ? separator +
          Math.abs(dec - i)
            .toFixed(precision)
            .slice(2)
        : '')
    )
  },

  last_x_months(count, from, to) {
    if (DateTime.isDateTime(from) && DateTime.isDateTime(to)) {
      let today = DateTime.local().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      let monthsAgo = today.minus({ month: count })
      return +to === +today && +from === +monthsAgo
    } else {
      console.warn('Using deprecated date obj in last_x_months()', from, to)

      let today = new Date()
      today.setHours(0, 0, 0, 0)
      let monthsAgo = new Date()
      monthsAgo.setMonth(monthsAgo.getMonth() - count)
      monthsAgo.setHours(0, 0, 0, 0)
      return to.getTime() === today.getTime() && from.getTime() === monthsAgo.getTime()
    }
  },

  get_week_number(dateTime) {
    if (DateTime.isDateTime(dateTime)) {
      // NOTE: Luxon's DateTime.weekNumber caches its value inside the object, which triggers a Vuex
      // "mutation outside of store" error. Before calculating the weekNumber we clone the object.
      return {
        number: DateTime.fromISO(dateTime.toISO()).weekNumber,
        year: dateTime.year,
      }
    } else {
      console.warn('Using deprecated date obj in get_week_number()', dateTime)

      let d = new Date(Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()))
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
      let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
      let weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
      return {
        number: weekNo,
        year: d.getUTCFullYear(),
      }
    }
  },

  format_ordinals(n, $i18n) {
    const i18_key = `ordinals.${ordinal_plural_rules.select(n)}`
    if ($i18n.te(i18_key)) return `${n}${$i18n.t(i18_key)}`
    return `${n}`
  },

  sort_days(days) {
    // there's no standardized way to get this array from a locale or a country code
    // there's an opened issue for this: https://github.com/tc39/ecma402/issues/6
    const ordering = [1, 2, 3, 4, 5, 6, 0]
    return [...days].sort((a, b) => ordering.indexOf(a) - ordering.indexOf(b))
  },

  get_diff_from_now(target_date) {
    const now = DateTime.now()
    return vl_time.parse_as_local(target_date).diff(now, ['days', 'hours', 'minutes', 'seconds']).toObject()
  },
}

const working_hours = {
  // function to generate blank time intervals for the working hours logs
  // you can pass in start/end times (as luxon DateTime) with whatever dates, they will be overwritten here
  blank_interval(day_index, start_time = DateTime.fromISO('2000-01-01T09:00:00.000'), end_time = DateTime.fromISO('2000-01-01T17:00:00.000')) {
    // 2000-01-01 is used because of the default conversion from a Time object to a datetime
    // default start and end times for intervals were specified as defined below
    return {
      day_index: day_index,
      start_time: start_time.set({ year: 2000, month: 1, day: 1 }),
      end_time: end_time.set({ year: 2000, month: 1, day: 1 }),
    }
  },
}

//
// These functions remain outside the plugin because we don't want them to be visible
//

//
// Returns number of estimated spots count for a time span
//
function spots_of_span(span, Vue) {
  return spots_of_span_with_alternate_capacity(span, span.capacity, Vue)
}

//
// Returns number of estimated spots count for a time span, with specified capacity limit other than slot capacity
//
function spots_of_span_with_alternate_capacity(span, capacity, Vue) {
  if (invalid_params(span, Vue)) return null

  // We only supply the spots count if slots dont' overlap
  if (span.interval < span.duration) return null

  // Calculate last entry
  const first_entry = vl_time.parse_time(span.start_time)
  let last_entry = null
  if (span.last_entry_time != null && span.last_entry_time !== '') {
    last_entry = vl_time.parse_time(span.last_entry_time)
  } else if (span.end_time != null && span.end_time !== '') {
    last_entry = vl_time.parse_time(span.end_time)
    if (last_entry) {
      last_entry = last_entry.minus({ minutes: span.duration })
      last_entry = first_entry > last_entry ? first_entry : last_entry
    }
  }
  if (!last_entry) return null

  // Calculate the time in minutes between the first and last entries
  const diff = last_entry.diff(first_entry, 'minutes')

  // Finally, calculate how many people can enter the location within this time span
  const slices = Math.floor(diff.toObject().minutes / span.interval)
  return (slices + 1) * capacity
}

//
// Validates time span parameters
//
function invalid_params(span, Vue) {
  // Make sure all fields are present
  const mandatory = ['start_time', 'end_time', 'duration', 'interval', 'capacity']
  if (mandatory.some(n => vl_utils(Vue).is_blank(span[n]))) return true

  // Make sure duration, interval, capacity are positive numbers
  const capacity = parseInt(span.capacity)
  const duration = parseInt(span.duration)
  const interval = parseInt(span.interval)
  if (isNaN(capacity) || isNaN(duration) || isNaN(interval)) return true
  if (capacity < 0 || duration <= 0 || interval <= 0) return true
  return false
}

let VerticalLifePlugin = {
  install(Vue) {
    // These are here for global static access, let's see if they are needed:
    Vue.localisation = localisation
    Vue.$vl_utils = vl_utils(Vue)
    Vue.$vl_time = vl_time
    Vue.$working_hours = working_hours

    // Hook the plugins to the vue instances
    Vue.prototype.$localisation = localisation
    Vue.prototype.$vl_utils = Vue.$vl_utils
    Vue.prototype.$vl_time = Vue.$vl_time
    Vue.prototype.$working_hours = working_hours

    window.$vl_time = Vue.$vl_time
    window.$vl_utils = Vue.$vl_utils

    Vue.smcb_axios = smcb_axios

    Vue.mixin({
      setup() {
        // eslint-disable-next-line vue/no-unused-properties
        return { use: window.vl_use_all }
      },
    })
  },
}

export default VerticalLifePlugin
